import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import { navigate } from "gatsby"
import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import styled from "styled-components"
import { loginFirebase, signUpFirebase } from "../redux/firebaseAction"

const LoginForm = () => {
  const dispatch = useDispatch()
  const [firstName, setfirstName] = useState("")
  const [showSignUpForm, setshowSignUpForm] = useState(false)
  const [lastName, setsecondName] = useState("")
  const [mail, setmail] = useState("")
  const [password, setPassword] = useState("")

  const { Error, isLoggedin } = useSelector(state => ({ ...state.mainReducer }))

  const login = event => {
    event.preventDefault()
    dispatch(loginFirebase(mail, password))
  }

  const signUp = event => {
    event.preventDefault()
    dispatch(signUpFirebase(mail, password, firstName, lastName))
    RedirectAfterSignUp(isLoggedin)
  }

  const RedirectAfterSignUp = isLoggedin => {
    if (isLoggedin) {
      navigate("/dashboard")
    } else navigate("/dashboard")
  }

  return (
    <Grid container item justify="center">
      <Grid item>
        {showSignUpForm ? (
          <Form>
            <div>
              <div>
                <TextField
                  margin="normal"
                  label="First Name"
                  type="text"
                  placeholder="Enter Username"
                  name="firstname"
                  required
                  onChange={e => setfirstName(e.target.value)}
                />
              </div>
              <div>
                <TextField
                  margin="normal"
                  label="Last Name"
                  type="text"
                  placeholder="Enter Username"
                  name="lastname"
                  required
                  onChange={e => setsecondName(e.target.value)}
                />
              </div>

              <div>
                <TextField
                  margin="normal"
                  label="Email"
                  type="text"
                  placeholder="Enter Username"
                  name="email"
                  required
                  onChange={e => setmail(e.target.value)}
                />
              </div>

              <div>
                <TextField
                  label="Password"
                  type="password"
                  placeholder="Enter Password"
                  name="psw"
                  required
                  onChange={e => setPassword(e.target.value)}
                />
              </div>
              <div className="loginbuttons">
                <Button
                  variant="outlined"
                  type="submit"
                  onClick={() => setshowSignUpForm(!showSignUpForm)}
                >
                  {" "}
                  Login
                </Button>
                <Button variant="outlined" type="submit" onClick={signUp}>
                  {" "}
                  Sign Up
                </Button>
                <p className="Error"> {Error}</p>
              </div>
            </div>
          </Form>
        ) : (
            <Form>
              <div>
                <div>
                  <TextField
                    id="standard-name"
                    margin="normal"
                    label="Email"
                    onChange={e => setmail(e.target.value)}
                  />
                </div>

                <div>
                  <TextField
                    id="standard-uncontrolled"
                    label="Password"
                    type="password"
                    placeholder="Enter Password"
                    name="psw"
                    required
                    onChange={e => setPassword(e.target.value)}
                  />
                </div>
                <div className="loginbuttons">
                  <Button variant="outlined" type="submit" onClick={login}>
                    {" "}
                    Login
                </Button>
                  <Button
                    variant="outlined"
                    type="submit"
                    onClick={() => setshowSignUpForm(!showSignUpForm)}
                  >
                    {" "}
                    Sign Up
                </Button>
                  <p className="Error"> {Error}</p>
                </div>
              </div>
            </Form>
          )}
      </Grid>
    </Grid>
  )
}

export default LoginForm

const Form = styled.form`
 
  font-family: "GT-Walsheim-Regular", sans-serif;
  font-weight: 700;
  font-size: 1rem;
  color: #323232;
  margin-top: 10%;

  label {
    font-family: "GT-Walsheim-Regular", sans-serif;
    font-size: 0.9rem;
  }

  button {
    font-size: 0.8rem;
    margin: 5px;
    background-color: #232323;
    color: #fff;
    font-family: "GT-Walsheim-Regular", sans-serif;
  }

  .loginbuttons {
    margin: 1px;
    
  }
  .Error {
    color: red;
  }
  
  
  @media (max-width: 794px) {
    button {
      font-size: 1rem;
       margin-top: 15px;
    }
     label {
      font-size: 1rem;
         }
  }
  
  
`
